import React, { useRef } from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import Text from '../components/text';
import Button from '../components/button';
import UploadResume from '../components/modal/upload-resume';
import Jobimage from '../assets/images/find-jobs-computer.png';
import Femaleicon from '../assets/images/find-jobs-jobster-female-icon.png';
import Maleicon from '../assets/images/find-jobs-jobster-male-icon.png';
import headQuote from '../assets/images/find-jobs-head-quote.png';
import tailQuote from '../assets/images/find-jobs-tail-quote.png';
import step1 from '../assets/images/find-jobs-step1.png';
import step2 from '../assets/images/find-jobs-step2.png';
import step3 from '../assets/images/find-jobs-step3.png';

function FindJobs() {
    // CSS
    const testimonyBox = {
        boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        overflow: 'hidden'
    }

    const testimonyContent = {
        backgroundImage: `url(${headQuote}), url(${tailQuote})`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundPosition: 'top 10px left 10px, bottom 10px right 10px',
        backgroundSize: '10px, 10px',
        height: '180px'
    }

    const testimonyInfo = {
        height: '147px'
    }

    // Modal
    const uploadResumeModal = useRef();

    const clients = [
        { name: 'Cathy', title: 'Actuarial Analyst at Major Insurance Company', gender: 'Female', content: 'I got lots of interview opportunities after applying for the openings Jobster recommended. I received my dream offer after that.' },
        { name: 'Camellia', title: 'Software Developer II at Large Tech Startup', gender: 'Female', content: 'I got 2 offers in only one month with help from Jobster.' },
        { name: 'Sheryl', title: 'Business Analyst at Major Bank', gender: 'Male', content: 'I received tremendous help from Jobster. In three months I got my dream offer.' }
    ]

    const testimony = clients.map((client, i) =>
        <div className='col-md-4 my-3' key={i}>
            <div style={testimonyBox}>
                <div className='d-flex p-5 align-items-center justify-content-center' style={testimonyContent}>
                    <p className='text-center'>{client.content}</p>
                </div>
                <div className="bg-blue py-4 px-3 d-flex align-items-center" style={testimonyInfo}>
                    <div className="d-flex">
                        <img src={client.gender == 'Female' ? Femaleicon : Maleicon} width='80' height='80' />
                        <div className='pl-lg-4 pl-3'>
                            <p>{client.name}</p>
                            <p>{client.title}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'find-jobs::text::title-name' })}`}
                </title>
            </Helmet>
            <div className='my-5 pt-5 justify-content-center'>
                <div className='col-lg-10 m-auto'>
                    <div className='row align-items-top'>
                        <div className='col-lg-6 m-auto'>
                            <h1 className='text-blue font-weight-bold font-size-normal'>
                                <Text id='find-jobs::text::title'></Text>
                            </h1>
                            <p className='font-weight-light font-size-smaller'>
                                <Text id='find-jobs::text::desc'></Text>
                            </p>
                            <div className="d-lg-flex align-items-center text-center text-lg-left">
                                <Button size='lg' onClick={() => uploadResumeModal.current.showModal()}>
                                    <Text id='home::button::upload-resume' />
                                </Button>
                                <div className='pl-lg-5 mt-1'>
                                    <a href='#steps'>
                                        <small className='text-blue'>
                                            <Text id='home::link::learn-more' /> <ArrowRight />
                                        </small>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <img className='w-100' src={Jobimage} alt='' />
                        </div>
                    </div>
                    <div className='row justify-content-center my-5'>
                        <div className='col-10'>
                            <p className='font-weight-lighter font-size-small text-center'>
                                <Text id='find-jobs::text::desc2' values={{
                                    bold: msg => <span className='font-weight-bold'>{msg}</span>
                                }}></Text>
                            </p>
                        </div>
                    </div>
                    <div className='row' id='steps'>
                        <div className='col-md-4 my-3 text-center'>
                            <p className='font-size-smaller font-weight-light mb-0'>
                                <Text id='find-jobs::steps::step1::text::step1'></Text>
                            </p>
                            <img className='my-4' src={step1} height='97' />
                            <p className='font-size-smaller mb-0'>
                                <Text id='find-jobs::steps::step1::text::title'></Text>
                            </p>
                            <p className='font-size-smaller font-weight-lighter'>
                                <Text id='find-jobs::steps::step1::text::subtitle'></Text>
                            </p>
                            <p className='font-size-smaller font-weight-lighter'>
                                <Text id='find-jobs::steps::step1::text::content'></Text>
                            </p>
                        </div>
                        <div className='col-md-4 my-3 text-center'>
                            <p className='font-size-smaller font-weight-light mb-0'>
                                <Text id='find-jobs::steps::step2::text::step2'></Text>
                            </p>
                            <img className='my-4' src={step2} height='97' />
                            <p className='font-size-smaller mb-0'>
                                <Text id='find-jobs::steps::step2::text::title'></Text>
                            </p>
                            <p className='font-size-smaller font-weight-lighter'>
                                <Text id='find-jobs::steps::step2::text::subtitle'></Text>
                            </p>
                            <p className='font-size-smaller font-weight-lighter'>
                                <Text id='find-jobs::steps::step2::text::content'></Text>
                            </p>
                        </div>
                        <div className='col-md-4 my-3 text-center'>
                            <p className='font-size-smaller font-weight-light mb-0'>
                                <Text id='find-jobs::steps::step3::text::step3'></Text>
                            </p>
                            <img className='my-4' src={step3} height='97' />
                            <p className='font-size-smaller mb-0'>
                                <Text id='find-jobs::steps::step3::text::title'></Text>
                            </p>
                            <p className='font-size-smaller font-weight-lighter'>
                                <Text id='find-jobs::steps::step3::text::subtitle'></Text>
                            </p>
                            <p className='font-size-smaller font-weight-lighter'>
                                <Text id='find-jobs::steps::step3::text::content'></Text>
                            </p>
                        </div>
                    </div>
                    <div className='text-center my-5'>
                        <Button className='mx-auto' size='lg' onClick={() => uploadResumeModal.current.showModal()}>
                            <Text id='home::button::upload-resume' />
                        </Button>
                    </div>
                    <h2 className='text-blue mb-0 text-center font-weight-light'>
                        <Text id='find-jobs::testimony::text::title'></Text>
                    </h2>
                    <h2 className='text-blue font-weight-lighter mt-0 text-center'>
                        <Text id='find-jobs::testimony::text::subtitle'></Text>
                    </h2>
                    <div className="row mt-5">
                        {testimony}
                    </div>
                </div>
                <UploadResume ref={uploadResumeModal} />
            </div>
        </>
    );
}

export default FindJobs;
