import { Input, Form, Alert } from 'antd';
import React, { useState } from 'react';
import { useLocation, useParams, Redirect } from 'react-router';
import Button from '../components/button.js';
import Text from '../components/text';
import queryString from 'query-string';
import { APIs, Pages } from '../utils/constants.js';

function ResetPassword() {
    const { id } = useParams();
    const { search } = useLocation();
    const queryParams = queryString.parse(search);
    const [form] = Form.useForm();

    // States
    const [alerts, setAlerts] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [fields, setFields] = useState([
        {
            name: ['new_password'],
            value: ''
        },
        {
            name: ['confirm_password'],
            value: ''
        }
    ]);

    // CSS
    const maxWidth500 = {
        maxWidth: '500px'
    };
    const left50 = {
        left: '50%'
    }
    const right50 = {
        right: '50%'
    }

    const handleErrorAlerts = (errorCode, currentAlerts) => {
        setAlerts([
            ...currentAlerts,
            <Alert
                key={alerts.length}
                message={errorCode}
                type='error'
                closable
                banner
            />
        ]);
    }

    const handleError = () => {
        // console.log(e);
    }

    const handleResetPassword = () => {
        form
            .validateFields()
            .then(async values => {
                const newPassword = values.new_password.trim();
                const confirmPassword = values.confirm_password.trim();
                try {
                    const response = await fetch(APIs.RESET_PASSWORD, {
                        method: 'POST', body: JSON.stringify({
                            user: id,
                            token: queryParams.token,
                            password: newPassword,
                            confirm_password: confirmPassword
                        })
                    });
                    const r = await response.json();
                    if (response.ok) {
                        setRedirect(true);
                    } else {
                        handleErrorAlerts(r.Error, alerts);
                    }
                } catch (error) {
                    handleError();
                }
            })
    }

    if (redirect) {
        return (
            <Redirect to={Pages.RESET_PASSWORD_SUCCESS} />
        );
    }

    return (
        <div className='row my-5 pt-5 vh-100'>
            <div className='position-absolute' style={left50}>
                <div className='position-relative r-50' style={right50}>
                    {alerts.length > 0 && alerts}
                </div>
            </div>
            <div className='col-lg-10 offset-lg-1 text-center'>
                <h1 className='font-size-small my-lg-5'>
                    <Text id='reset-password::title::set-new-password'></Text>
                </h1>
                <Form
                    form={form}
                    fields={fields}
                    onFieldsChange={(_, newFields) => {
                        setFields(newFields);
                    }}
                    layout='vertical'
                    className='m-auto text-left'
                    style={maxWidth500}
                    name='reset-password-form'>
                    <Form.Item
                        name='new_password'
                        label={<Text id='reset-password::form::text::new-password' />}
                        rules={[
                            { required: true, message: <Text id='reset-password::form::text::new-password-required' /> }
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name='confirm_password'
                        label={<Text id='reset-password::form::text::confirm-password' />}
                        rules={[
                            { required: true, message: <Text id='reset-password::form::text::confirm-password-required' /> },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(<Text id='reset-password::form::text::password-does-not-match' />);
                                },
                            }),
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item className='text-center'>
                        <Button onClick={handleResetPassword}>
                            <Text id='reset-password::form::button::reset-password' />
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default ResetPassword;
