import React from 'react';
import Text from '../components/text';
import Teamicon1 from '../assets/images/Team-1.png';
import Teamicon2 from '../assets/images/Team-2.png';
import Teamicon3 from '../assets/images/Team-3.png';
import Teamicon4 from '../assets/images/Team-4.png';
import Teamicon5 from '../assets/images/Team-5.png';
import Teamicon6 from '../assets/images/Team-6.png';
import Teamicon7 from '../assets/images/Team-7.png';
import Teamicon8 from '../assets/images/Team-8.png';
import LinkedInIcon from '../assets/images/linkedin-icon.png';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

function AboutUs() {
    const teamBox = {
        //minWidth: '20%',
        width: '75%',
        padding: '0 20px'
    }

    const persons = [
        { name: 'Loewe Ke', title: 'Co-Founder, CEO', link: 'https://www.linkedin.com/in/loeweke/', icon: Teamicon1 },
        { name: 'Cindy Zhong', title: 'Co-Founder, Head of Data Science', link: 'https://www.linkedin.com/in/cindyzhong/', icon: Teamicon2 },
        { name: 'Jehan Xue', title: 'Technical Lead', link: 'https://www.linkedin.com/in/zhehan/', icon: Teamicon3 },
        { name: 'Patrick Lee', title: 'Head of Marketing & Product', link: 'https://www.linkedin.com/in/patrick733/', icon: Teamicon4 },
        { name: 'Summer Xu', title: 'Design Lead', link: 'https://www.linkedin.com/in/summer-x-796b71ba/', icon: Teamicon5 },
        { name: 'Jonathan Feng', title: 'Software Engineer Intern', link: 'https://www.linkedin.com/in/jonathanfeng/', icon: Teamicon6 },
        { name: 'Cristiano Afonso da Silva', title: 'Product Designer and Frontend Developer Intern', link: 'https://www.linkedin.com/in/cristianoafonsodasilva/', icon: Teamicon7 },
        { name: 'Jiajin Wu', title: 'Backend Developer Intern', link: 'https://www.linkedin.com/in/jiajin-wu-936121253/', icon: Teamicon8 }
    ];

    const teamMembers = persons.map((member, i) =>
        <div className='text-center col-lg-4 col-md-6 col-sm-12 mb-5 mb-md-0 mx-auto' key={i} style={teamBox}>
            <img src={member.icon} className='w-50 h-auto' />
            <p className='mt-3 mb-1 font-weight-bold font-size-smaller'>
                {member.name}
            </p>
            <p className='mb-2 font-weight-light'>
                {member.title}
            </p>
            <a href={member.link} target='_blank' rel='noopener noreferrer'>
                <img src={LinkedInIcon} width='30' />
            </a>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'about-us::text::title-name' })}`}
                </title>
            </Helmet>
            <div className='my-5 pt-5'>
                <div className='col-lg-7 offset-lg-1'>
                    <h1 className='text-blue font-weight-bold font-size-normal'>
                        <Text id='about-us::text::about-jobster'></Text>
                    </h1>
                    <div className='mt-5 font-size-smaller font-weight-light'>
                        <p>
                            <Text id='about-us::text::desc1'></Text>
                        </p>
                        <p>
                            <Text id='about-us::text::desc2'></Text>
                        </p>
                        <p>
                            <Text id='about-us::text::desc3'></Text>
                        </p>
                    </div>
                </div>
                <div className='col-lg-10 offset-lg-1 mt-5'>
                    <h1 className='text-blue font-weight-bold font-size-normal'>
                        <Text id='about-us::text::meet-the-team'></Text>
                    </h1>
                    <div className='d-lg-flex justify-content-center justify-content-between mt-5 text-center'>
                        {teamMembers.slice(0, 3)}
                    </div>
                    <div className='d-lg-flex justify-content-between mt-5'>
                        {teamMembers.slice(3, 6)}
                    </div>
                    <div className='d-lg-flex justify-content-between mt-5'>
                        {teamMembers.slice(6, 9)}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUs;
