import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { BookmarkStar, BookmarkStarFill } from 'react-bootstrap-icons';
import { Tag, Tabs, Progress, Image, List, Empty, Skeleton, Switch} from 'antd';
import { APIs } from '../utils/constants';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { getUser } from '../utils/auth';
import Button from '../components/button';
import Text from '../components/text';
import Login from '../components/modal/login';
import checkmark from '../assets/images/checkmark.png';
import defaultCompany from '../assets/images/default-company.png';

function Result() {
    const user = getUser();
    const { TabPane } = Tabs;
    const { id } = useParams();

    // Modal
    const loginModal = useRef();

    // State
    const [loadingRankSampleJobs, setLoadingRankSampleJobs] = useState(true);
    const [loadingJobListing, setLoadingJobListing] = useState(true);
    const [sampleJobsRank, setSampleJobsRank] = useState([]);
    const [currentSampleJobIndex, setCurrentSampleJobIndex] = useState(0);
    const [displayJobs, setDisplayJobs] = useState([]);
    const [totalJobs, setTotalJobs] = useState(0);
    const [savedJobs, setSavedJobs] = useState([]);
    const [internship, setInternship] = useState(false);

    // CSS
    const lightHeight40 = {
        lineHeight: '40px'
    }
    const height80 = {
        height: '80px'
    }
    const companyImage = {
        maxHeight: '80px',
        maxWidth: '100%'
    }
    const backgroundColorLightBlue = {
        backgroundColor: '#F4F6F9'
    }
    const lightBorder = {
        border: '1px solid #f0f0f0'
    }
    const lightBorderWithoutTop = {
        border: '1px solid #f0f0f0',
        borderTop: 'none'
    }
    const bookmarkCSS = {
        top: '-13px',
        right: '1.5rem',
        fontSize: '30px'
    }

    // Actions
    const handleMainTabChange = e => {
        setCurrentSampleJobIndex(parseInt(e));
        getJobListings(sampleJobsRank[parseInt(e)].id);
    }
    const handleLoadAllJobs = jobId => {
        const date = new Date().toISOString().split('T')[0];
        const cachedResponse = sessionStorage.getItem(id + jobId + date);
        const cachedResponseJson = JSON.parse(cachedResponse);
        setDisplayJobs(cachedResponseJson.items);
    }
    const handleLoadLessJobs = () => {
        setDisplayJobs(displayJobs.slice(0, 6));
    }
    const handleError = () => {
        // console.log(e);
    }
    const saveJob = async jobId => {
        if (user) {
            try {
                const response = await fetch(APIs.SAVE_JOB, {
                    method: 'POST', body: JSON.stringify({
                        user: user.id,
                        token: user.deviceIdentity,
                        job: jobId
                    })
                });
                if (response.ok) {
                    const newSavedJobs = [...savedJobs, jobId];
                    setSavedJobs(newSavedJobs);
                    sessionStorage.setItem(user.id + '-saved_jobs', JSON.stringify(newSavedJobs));
                } else {
                    handleError();
                }
            } catch (error) {
                handleError();
            }
        } else {
            loginModal.current.showModal();
        }
    }
    const unsaveJob = async jobId => {
        if (user) {
            try {
                const response = await fetch(APIs.UNSAVE_JOB, {
                    method: 'POST', body: JSON.stringify({
                        user: user.id,
                        token: user.deviceIdentity,
                        job: jobId
                    })
                });
                if (response.ok) {
                    const index = savedJobs.indexOf(jobId);
                    if (index > -1) {
                        savedJobs.splice(index, 1);
                    }
                    setSavedJobs([...savedJobs]);
                    sessionStorage.setItem(user.id + '-saved_jobs', JSON.stringify(savedJobs));
                } else {
                    handleError();
                }
            } catch (error) {
                handleError();
            }
        } else {
            loginModal.current.showModal();
        }
    }
    const getJobListings = async jobId => {
        setLoadingJobListing(true);
        const date = new Date().toISOString().split('T')[0];
        let cachedResponse = sessionStorage.getItem(id + jobId + date);
        const cachedSavedJobs = sessionStorage.getItem(user.id + '-saved_jobs');

        if (user && cachedSavedJobs) {
            const cachedSavedJobsJson = JSON.parse(cachedSavedJobs);
            setSavedJobs(cachedSavedJobsJson);
        } else {
            cachedResponse = false;
        }

        if (cachedResponse) {
            const cachedResponseJson = JSON.parse(cachedResponse);
            setTotalJobs(cachedResponseJson.count);
            setDisplayJobs(cachedResponseJson.items.slice(0, 6));
            setLoadingJobListing(false);
        } else {
            try {
                const response = await fetch(APIs.GET_JOB_LISTINGS, {
                    method: 'POST', body: JSON.stringify({
                        resumeId: id,
                        jobId: jobId,
                        userId: user.id ? user.id : null
                    })
                });
                if (response.ok) {
                    const r = await response.json();
                    setTotalJobs(r.count);
                    setDisplayJobs(r.items.slice(0, 6));
                    sessionStorage.setItem(id + jobId + date, JSON.stringify(r));
                    if (user) {
                        setSavedJobs(r.saved);
                        sessionStorage.setItem(user.id + '-saved_jobs', JSON.stringify(r.saved));
                    }
                    setLoadingJobListing(false);
                } else {
                    handleError();
                }
            } catch {
                handleError();
            }
        }
    }

    useEffect(() => {
        const fetchSampleJobsRank = async () => {
            setLoadingRankSampleJobs(true);
            try {
                const response = await fetch(APIs.RANK_SAMPLE_JOBS + '?resumeId=' + id + '&internship=' + internship, { method: 'GET' });
                if (response.ok) {
                    const r = await response.json();
                    setSampleJobsRank(r.items);
                    setLoadingRankSampleJobs(false);
                    getJobListings(r.items[currentSampleJobIndex].id);
                } else {
                    handleError();
                }
            } catch (error) {
                handleError(error);
            }
        };

        fetchSampleJobsRank();
    }, [internship]);

    // UI
    const getResumeQuality = percentage => {
        if (percentage > 95) {
            return 'A+';
        } else if (percentage > 90) {
            return 'A';
        } else if (percentage > 85) {
            return 'A-';
        } else if (percentage > 80) {
            return 'B+';
        } else if (percentage > 75) {
            return 'B';
        } else if (percentage > 70) {
            return 'B-';
        } else if (percentage > 65) {
            return 'C+';
        } else if (percentage > 60) {
            return 'C';
        } else if (percentage > 55) {
            return 'C-';
        } else {
            return 'F';
        }
    }

    const getTabContent = () => {
        const sampleJob = sampleJobsRank[currentSampleJobIndex];
        return (
            <>
                <div className='col-10 offset-1 bg-white py-4 d-lg-flex text-center mb-5' style={lightBorder}>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className='mb-3 mb-lg-5'>
                            <Text id='result::sample-job::text::resume-quality'></Text>
                        </div>
                        <h1 className='font-size-small'>
                            {loadingRankSampleJobs ?
                                <Skeleton.Button active='true' size='large' />
                                :
                                getResumeQuality(100-sampleJob.percentage)}
                        </h1>
                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className='mb-3 mb-lg-5'>
                            <Text id='result::sample-job::text::candidate-rank'></Text>
                        </div>
                        <h1 className='font-size-small'>
                            {loadingRankSampleJobs ?
                                <Skeleton.Button active='true' size='large' />
                                :
                                <Text id='result::sample-job::text::top-percentage' values={{
                                    percentage: () => sampleJob.percentage
                                }} />}
                        </h1>
                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className='mb-3 mb-lg-5'>
                            <Text id='result::sample-job::text::interview-ratio'></Text>
                        </div>
                        <h1 className='font-size-small'>
                            {loadingRankSampleJobs ?
                                <Skeleton.Button active='true' size='large' />
                                :
                                `${sampleJob.ratio} : 1`}
                        </h1>
                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className='mb-3 mb-lg-5'>
                            <Text id='result::sample-job::text::salary'></Text>
                        </div>
                        <h1 className='font-size-small'>
                            {loadingRankSampleJobs ?
                                <Skeleton.Button active='true' size='large' />
                                :
                                <Text id='result::sample-job::text::average-salary' values={{
                                    salary: () => sampleJob.salary,
                                    small: msg => <span className='font-size-smaller'>{msg}</span>
                                }} />}
                        </h1>
                    </div>
                </div>
                <div id='related-jobs' className='col-10 offset-1 bg-white py-3' style={lightBorder}>
                    <div className='col-12'>
                        <span className='font-size-smaller font-weight-bold'>
                            <Text id='result::job-listing::text::related-jobs' />
                        </span>
                        <span className='ml-3'>
                            <Text id='result::job-listing::text::found-jobs' values={{
                                number: () => totalJobs
                            }} />
                        </span>
                    </div>
                </div>
                <div className='col-10 offset-1 p-0 bg-white' style={lightBorderWithoutTop}>
                    <List
                        itemLayout='horizontal'
                        loadMore={<div className='p-0 bg-gray text-center'>
                            {
                                displayJobs.length &&
                                totalJobs > displayJobs.length &&
                                <a onClick={() => handleLoadAllJobs(sampleJob.id)}>
                                    <div className='font-weight-normal text-blue py-3'>
                                        <Text id='result::job-listing::text::load-all-jobs' />
                                    </div>
                                </a>
                            }
                            {
                                displayJobs.length &&
                                totalJobs === displayJobs.length &&
                                <a href='#related-jobs' onClick={handleLoadLessJobs}>
                                    <div className='font-weight-normal text-blue py-3'>
                                        <Text id='result::job-listing::text::load-less-jobs' />
                                    </div>
                                </a>
                            }
                        </div>}
                        dataSource={loadingJobListing ? Array(6).fill(0) : displayJobs}
                        renderItem={(job, index) => (
                            <List.Item className='mx-4 position-relative'>
                                <div className='col-12 d-lg-flex px-0 py-3 position-inherit' key={index}>
                                    <div className='col-lg-2 text-center my-auto d-flex' style={height80}>
                                        {loadingJobListing ? <Skeleton.Image className='m-auto' /> : <img className='m-auto' src={job.company_image ? job.company_image : defaultCompany} style={companyImage}></img>}
                                    </div>
                                    <div className='col-lg-2 mt-3 my-lg-auto text-center text-lg-left'>
                                        {loadingJobListing ? <Skeleton.Button active='true' /> : job.company}
                                    </div>
                                    <div className='col-lg-3 mb-3 my-lg-auto text-center text-lg-left font-weight-bold'>
                                        {loadingJobListing ? <Skeleton.Button active='true' className='w-75' /> : job.position}
                                    </div>
                                    <div className='col-lg-2 text-center my-3 my-lg-auto'>
                                        {loadingJobListing ?
                                            <Skeleton.Button active='true' className='w-75' size='small' />
                                            :
                                            <Text id='result::job-listing::text::percentage-match' values={{
                                                percentage: () => job.percentage
                                            }} />

                                        }
                                        <Progress percent={loadingJobListing ? 0 : job.percentage} showInfo={false} />
                                    </div>
                                    <div className='col-lg-3 text-center my-3 my-lg-auto'>
                                        {loadingJobListing ?
                                            <Skeleton.Button active='true' className='w-75' />
                                            :
                                            <a href={job.link} target='_blank' rel='noopener noreferrer'>
                                                <Button size='sm'>
                                                    <Text id='result::job-listing::button::apply' />
                                                </Button>
                                            </a>

                                        }
                                    </div>
                                    {
                                        loadingJobListing ||
                                        (savedJobs.includes(job.id) ?
                                            <BookmarkStarFill className='position-absolute cursor-pointer text-warning' style={bookmarkCSS} onClick={() => unsaveJob(job.id)} />
                                            :
                                            <BookmarkStar className='position-absolute cursor-pointer text-gray' style={bookmarkCSS} onClick={() => saveJob(job.id)} />)
                                    }
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
                <div className='col-10 offset-1 mt-5 p-0 bg-white'>
                    <Tabs defaultActiveKey='sub0' className='sub-tab' animated={{ inkBar: true, tabPane: true }}>
                        <TabPane
                            tab={loadingRankSampleJobs ?
                                <Skeleton.Button active='true' />
                                :
                                <Text id='result::details-tab::text::job-escription' />}
                            key='sub0'
                            disabled={loadingRankSampleJobs}>
                            <div className='mt-3 mb-5'>
                                <span className='font-size-smaller font-weight-bold'>
                                    <Text id='result::job-requirements::text::related-skills' />
                                </span>
                                <div className='mt-3'>
                                    {loadingRankSampleJobs ?
                                        [0, 1, 2, 3, 4, 5].map(index => <Skeleton.Button active='true' size='small' className='mr-2' key={index} />)
                                        :
                                        sampleJob.skills.map(skill => <Tag color='blue' key={skill}>{skill}</Tag>)
                                    }
                                </div>
                            </div>
                            <div className='mt-3 mb-5'>
                                <span className='font-size-smaller font-weight-bold'>
                                    <Text id='result::job-requirements::text::sample-job-description' />
                                </span>
                                {loadingRankSampleJobs ?
                                    <Skeleton active paragraph={{ rows: 10 }} />
                                    :
                                    <div className='mt-3' dangerouslySetInnerHTML={{ __html: sampleJob.description }}></div>
                                }
                            </div>
                        </TabPane>
                        <TabPane
                            tab={loadingRankSampleJobs ?
                                <Skeleton.Button active='true' />
                                :
                                <Text id='result::details-tab::text::sample-resumes' />}
                            key='sub1'
                            disabled={loadingRankSampleJobs}>
                            <div className='mt-3 mb-5'>
                                {!loadingRankSampleJobs && sampleJob.sample_resumes.length > 0 ?
                                    <>
                                        <div className='font-size-smaller font-weight-light'>
                                            <Text id='result::sample-resumes::text::click-to-enlarge' />
                                        </div>
                                        <div className='col-12 d-flex mt-3'>
                                            {sampleJob.sample_resumes.map((resume, i) =>
                                                <div className='col-4' key={i}>
                                                    <Image className='w-100' src={'https://jobster.io/images/sample_resume/' + resume} />
                                                </div>)
                                            }
                                        </div>
                                    </> :
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </div>
                        </TabPane>
                        <TabPane
                            tab={loadingRankSampleJobs ?
                                <Skeleton.Button active='true' />
                                :
                                <Text id='result::details-tab::text::interview-questions' />}
                            key='sub2'
                            disabled={loadingRankSampleJobs}>
                            <div className='mt-3 mb-5'>
                                <List
                                    itemLayout='horizontal'
                                    dataSource={loadingRankSampleJobs ? null : sampleJob.interview}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.question}
                                                description={item.answer}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </>
        )
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'result::text::title-name' })}`}
                </title>
            </Helmet>
            <div>
                <div className='col-12 m-auto'>
                    <div className='col-10 offset-1 pt-5 pb-4 px-0'>
                        <div className='d-flex'>
                            <img src={checkmark} height='40' />
                            <h1 className='font-size-smaller ml-3 font-weight-bold' style={lightHeight40}>
                                <Text id='result::header::text::title'></Text>
                            </h1>
                        </div>
                        <div>
                            <Text id='result::header::text::description'></Text>
                        </div>
                    </div>
                    <div className="col-10 offset-1 px-0 filter-header">
                      <div className="tabs-wrapper">
                        <Tabs className="without-border" defaultActiveKey="0" onChange={handleMainTabChange} size="large">
                          {loadingRankSampleJobs ? (
                            <>
                              <TabPane disabled tab={<Skeleton.Button active />} key={0} />
                              <TabPane disabled tab={<Skeleton.Button active />} key={1} />
                              <TabPane disabled tab={<Skeleton.Button active />} key={2} />
                            </>
                          ) : (
                            sampleJobsRank.map((sampleJob, index) => (
                              <TabPane tab={sampleJob.position} key={index} />
                            ))
                          )}
                        </Tabs>
                      </div>
                      <div className="switch-wrapper">
                        <span className="mr-3">
                          <Text id="result::filter::text::intern" />
                        </span>
                        <Switch
                          checked={internship}
                          onChange={checked => setInternship(checked)}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                        />
                      </div>
                    </div>
                </div>
                <div className='col-12' style={backgroundColorLightBlue}>
                    {getTabContent()}
                </div>
            </div>
            <style>{`
              .filter-header {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
              }
              
              .tabs-wrapper {
                flex: 1;
                padding-left: 1rem;
                min-width: 300px;
              }

              .switch-wrapper {
                display: flex;
                align-items: center;
                border-left: 1px solid #ccc;
                padding-left: 1rem;
                margin-left: auto;
              }

              @media (max-width: 620px) {
                .switch-wrapper {
                  margin-left: 0;
                  margin-top: 1rem;
                }
              }
            `}</style>
            <Login ref={loginModal} />
        </React.Fragment>
    );
}

export default Result;