import React, { useRef } from 'react';
import { Row, Col, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Pages } from '../../utils/constants';
import { Helmet } from 'react-helmet-async';
import Button from '../../components/button';
import Text from '../../components/text';
import UploadResume from '../../components/modal/upload-resume';
import Avatar1 from '../../assets/images/lil-man-2.png';
import Avatar2 from '../../assets/images/lil-man-3.png';

function Start() {
    const uploadResumeModal = useRef();

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'resume-gpt::start::title-name' })}`}
                </title>
            </Helmet>
            <Row className='my-5 h-100 pt-5' style={{ display: 'inline-table' }}>
                <Col span={20} offset={2}>
                    <Row className='mb-5'>
                        <Col span={20} offset={2} className='text-center'>
                            <Typography.Title>
                                <Text id='resume-gpt::start::text::title' />
                            </Typography.Title>
                            <Typography.Title level={3} className='font-weight-light'>
                                <Text id='resume-gpt::start::text::subtitle' />
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col xs={{ span: 24 }} md={{ span: 6, offset: 4 }} className='text-center'>
                            <div>
                                <img className='mb-4' src={Avatar1} />
                            </div>
                            <Button onClick={() => uploadResumeModal.current.showModal(Pages.DASHBOARD_RESUME_OVERVIEW)}>
                                <Text id='resume-gpt::start::button::upload-my-resume' />
                            </Button>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 6, offset: 4 }} className='text-center'>
                            <div>
                                <img className='mb-4' src={Avatar2} />
                            </div>
                            <NavLink to={Pages.RESUME_GPT_RESUME}>
                                <Button>
                                    <Text id='resume-gpt::start::button::help-me-create-one' />
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <UploadResume ref={uploadResumeModal} redirect={Pages.RESUME_GPT_RESUME} />
        </React.Fragment>
    )
}
export default Start;